<template>
  <div>
    <title>Learner Page</title>
    <UserPageContent
        :content="userTypeContentLearner"
    />
  </div>
</template>

<script>
import UserPageContent from "@/components/UserPageContent";
import {mapGetters} from "vuex";

export default {
  name: "Learners",
  metaInfo: {
    title: 'LearningPlus for Individuals',
    meta:[
      { description: 'Join thousands of others who have used our digital learning system to keep up in a fast-paced jobs market. Whether you\'re looking to take the next step in your career or train in a new field, we have an extensive range of courses that can help you achieve this. ' },
      { image: 'https://www.learningplus.co.uk/images/customer/learner.jpg' },
    ],
  },
  components: {UserPageContent},
  computed: {
    ...mapGetters(['userTypeContentLearner']),
  },
};
</script>
